import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'for-doctors',
    loadChildren: () => import('./for-doctors/for-doctors.module').then( m => m.ForDoctorsPageModule)
  },
  {
    path: 'fwd-takaful',
    loadChildren: () => import('./fwd-takaful/fwd-takaful.module').then( m => m.FwdTakafulPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then( m => m.FaqPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy/privacy.module').then( m => m.PrivacyPageModule)
  },

];

@NgModule({
  imports: [
    // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
        RouterModule.forRoot(routes,{useHash:true})

  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
